define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-ca", {
    "components": {
      "application-page-header": {
        "menu": {
          "content": {
            "contact-us": "Contact us",
            "home": "Dashboard",
            "how-it-works": "How does it work?",
            "join-campaign": "Join a campaign",
            "login": "Log in",
            "logout": "Logout",
            "organization": "My charity",
            "profile": "My profile",
            "search-organization": "Search for a charity",
            "suggest-an-organization": "Suggest a registered charity",
            "team-switcher": {
              "title": "Choose a team"
            }
          },
          "cookies": "Cookie settings",
          "terms-and-conditions": "Terms and conditions"
        }
      },
      "campaign-status-banner": {
        "allocations-left": "You can still allocate ${amountLeft} in donations. Allocate the funds now!",
        "already-donated": "A huge thank you for your generosity! If you wish, you can still make a personal donation.",
        "ended": "The campaign is over. Thank you for your donations!",
        "ends-soon": "Campaign ends in {daysLeft, plural, =1 {1 day} other {# days}}.",
        "ends-today": "Campaign ends today.",
        "never-donated": "It's the perfect time to make a personal donation!"
      },
      "dashboard": {
        "all-donations": {
          "completed": "Completed",
          "donations-sum": "Donations sum",
          "goal": "of {goal}",
          "hide-details": "Hide details",
          "my-allocations": "My part of {teamName}’s donation",
          "my-personal-donations": "My personal donations",
          "show-details": "Show details",
          "title": "All Donations"
        },
        "donate": "Make a Donation",
        "last-activities": {
          "earlier": "Earlier",
          "empty-message-1": "Be the <em>first</em> to donate.",
          "empty-message-2": "We invite you to do it now and contribute to the causes that matter to you. Every donation counts!",
          "hours-ago": "{hours, plural, =1 {1 hour} other {# hours}} ago",
          "less-than-a-minute-ago": "Less than a minute ago",
          "minutes-ago": "{minutes, plural, =1 {1 minute} other {# minutes}} ago",
          "this-week": "This Week",
          "title": "Last Activities",
          "today": "Today",
          "yesterday": "Yesterday"
        },
        "popular-categories": {
          "header-category": "Category",
          "header-organism-count": "Number of charities",
          "header-total": "Total",
          "organizations": "{count, plural, =1 {1 Charity} other {# Charities}}",
          "title": "Popular Categories"
        },
        "see-all-organizations": "View charities",
        "title": "Dashboard"
      },
      "form": {
        "translated-input": {
          "label": {
            "en": "EN",
            "fr": "FR"
          }
        }
      },
      "pages": {
        "consent": {
          "button-text": "Accept conditions",
          "checkbox-label": "I accept the conditions",
          "policy-description": "By accepting the conditions, you agree to Nooé’s platform terms of use. You can consult them at any time by clicking on <a target=\"_blank\" href=\"https://www.nooe.org/en/termes-et-conditions\">this link</a>.",
          "title": "Consent request"
        },
        "dashboard": {
          "donate": "Donate"
        },
        "discover": {
          "back-button": "Back",
          "loading": "Loading…",
          "loading-error": "Oops! AI didn't give us the expected results… Please try again.",
          "organization-results": {
            "no-result-text": "No nonprofit matches your search.",
            "register-organization": "Suggest a nonprofit"
          },
          "retry": "Retry",
          "search": "Search",
          "search-form": {
            "disclaimer": "As results are generated by artificial intelligence, they may vary and/or not meet your expectations. You may need to reformulate your request to obtain better results.",
            "placeholder": "A cause that is particularly close to my heart is…",
            "region": "Region",
            "title": "Tell us what matters to you"
          }
        },
        "donate": {
          "categories-list": {
            "title": "<em>Discover</em> registered charities"
          },
          "discover-with-ai": "Discover with AI",
          "organization-list": {
            "count": "{count} results for your search",
            "footer": {
              "load-more-button": "Load more"
            },
            "limit-exceeded": {
              "text": "The result limit has been reached. Please refine your search."
            },
            "no-params": "Start your search for a registered charity",
            "no-result": {
              "clear-filters": "Clear filters",
              "register-organization": "Suggest a charity",
              "text": "No charity matches your search."
            },
            "quotes": {
              "quote1": "Our mission is to increase the average annual donation in Quebec.",
              "quote2": "The Nooé platform features nearly 3,500 registered charities in Quebec and ready to receive donations.",
              "quote3": "In 2023, the average donation in Quebec was 249$, well below the Canadian average.",
              "quote4": "Nooé allows you to discover and support registered charities across the province of Quebec.",
              "quote5": "Did you know? <p>Nooé donates 100% of the funds raised to the charities. No fees are deducted from your donation.</p>",
              "quote6": "The Nooé platform can be used during events. Mobilize your participants by letting them choose the causes to support.",
              "quote7": "Did you know that you can choose to make your donation to the Nooé Foundation? <p>These donations fasten our development and allow us to achieve our mission.</p>",
              "quote8": "Did you know? <p>The Nooé Foundation issues tax receipts for donations, allowing you to benefit from the appropriate tax deductions.</p>"
            },
            "title": {
              "category": "{count, plural, =1 {Here is the result} other {Here are the # results}} of the category {category}",
              "default": "{count, plural, =1 {Here is your result} other {Here are your # results}}"
            }
          },
          "preselected-organization-list": {
            "title": "{organizationCount, plural, =1 {Available charity} other {The # available charities}} in your campaign"
          },
          "search-form": {
            "clear-all-button": "Clear all",
            "placeholder": {
              "category": "Categories",
              "keywords": "Enter keywords",
              "region": "Regions"
            },
            "search-button": "Search",
            "title": "Search for a charity",
            "update-filter-button": "Update"
          }
        },
        "donate-organization": {
          "back-button": "Back",
          "claim-modal": {
            "description": "Fill out the form below to claim this charity.",
            "on-error": "An error occurred while sending your request. Please try again later.",
            "on-success": "Your request has been successfully sent. We will contact you shortly.",
            "representative-email-error-message": "Please enter a valid email address.",
            "representative-email-placeholder": "Your email address",
            "representative-last-name-placeholder": "Your last name",
            "representative-name-placeholder": "Your name",
            "representative-phone-number-placeholder": "Your phone number",
            "representative-position-placeholder": "Your position within the charity",
            "submit": "Submit",
            "title": "Claim this charity"
          },
          "donations": {
            "personal-donation-button": "Make a personal donation",
            "personal-donation-button-repeat": "Make another donation",
            "team-donation-button": "Allocate your share of {teamName}'s donation"
          },
          "edit-organization": "Edit my charity",
          "impact": {
            "text-chunks": {
              "action-label": "here",
              "end": "to start sharing with Nooé donors the impact their donations will have on your charity.",
              "start": "Do you represent this charity? Let us know"
            },
            "title": "The impact of your donation"
          },
          "main-content": {
            "claim-organization-notice": "I represent this charity",
            "name": "Name",
            "region": "Region"
          },
          "meta": {
            "address": "Address",
            "email-body": "Hello,\n\nI would like to make these corrections for the charity: {organizationName}\n\n<< Please provide us with the information to be corrected >>",
            "email-subject": "Modifications needed to be made to a charity",
            "generated-content-notice-1": "This AI generated content contains errors?",
            "generated-content-notice-2": "Let us know!",
            "registration-number": "Registration number",
            "website": "Website"
          },
          "personal-donation-modal": {
            "donation-already-made": "You have already donated {amount} to this charity.",
            "donation-form": {
              "amount-placeholder": "Donation amount",
              "consent-section": {
                "checkbox-label": "I accept the conditions of use",
                "policy-description": "By accepting the conditions, you agree to Nooé’s platform terms of use. You can consult them at any time by clicking on <a target=\"_blank\" href=\"https://www.nooe.org/en/termes-et-conditions\">this link</a>."
              },
              "email": {
                "confirmation-placeholder": "Confirm your email address",
                "error-message": "Please enter a valid email address.",
                "placeholder": "Email address",
                "unmatched-error-message": "The email addresses provided do not match."
              },
              "error-messages": {
                "donation-amount-error-message": "Please enter an amount greater than zero.",
                "minimum-donation-amount-error-message": "To help us make a meaningful impact, we kindly request a minimum donation of {minimumDonation} dollars. Thank you for making a difference!"
              },
              "footer": {
                "message": "An official donation receipt will be issued.",
                "submit": "Confirm my donation",
                "total": "Total : {amount}"
              },
              "platform-amount": {
                "custom-description": "As an example, the credit card fee is {amount} per transaction.",
                "custom-title": "Other amount",
                "description": "A plateform made in Quebec giving back 100% of the donations to charities!",
                "options-fees": "Credit card fees ({amount})",
                "options-other": "Other",
                "options-percentage": "{percentage} ({amount})",
                "placeholder": "Enter amount here",
                "title": "Support Nooé!"
              }
            },
            "error-message": "An error occurred while processing your donation. Please try again later.",
            "payment-form": {
              "submit": "Submit"
            },
            "payment-success": {
              "close": "Close",
              "subtext": "An official donation receipt will be sent to you by email before the end of the year.",
              "text": "Your donation has been successfully processed!"
            },
            "title": "Complete your personal donation"
          },
          "suggested-organizations-modal": {
            "content": {
              "current-allocation": "Charity: {organizationName}",
              "current-amount-left": "Your balance for the campaign <b>{campaignName}</b> is <b>{amount}</b>",
              "deleted-text": "Your donation has been deleted",
              "deleted-title": "Confirmation",
              "success-title": "{amount} donation completed"
            },
            "deleted-title": "Donation deleted",
            "organization-list": {
              "title": "Here are some charities that might interest you."
            },
            "success-title": "Thank you for being generous"
          },
          "team-donation-modal": {
            "amount": {
              "current-allocation": "You have already made a donation of <b>{amount}</b> to this charity during the <b>{campaignName}</b> campaign",
              "current-amount-left": "Your balance for the campaign <b>{campaignName}</b> is <b>{amount}</b>",
              "no-amount-left": "The amount allocated for the <b>{campaignName}</b> campaign has been fully used.",
              "title": "Thank you for your donation."
            },
            "donation": {
              "amount-list": {
                "amount-left": "Donate all",
                "other-amount": "Other"
              },
              "custom-amount": {
                "cancel-button": "Cancel",
                "error-messages": {
                  "maximum-allocation": "The donation amount exceeds your available balance.",
                  "minimum-value": "To help us make a meaningful impact, we kindly request a minimum donation of {minimumDonation} dollars. Thank you for making a difference!",
                  "no-value": "Please enter an amount greater than zero."
                },
                "placeholder": "Enter an amount"
              },
              "delete-button": "Delete",
              "edit-button": "Edit",
              "mention-text": "You can modify or delete a donation already made since the campaign is still in progress."
            },
            "error-box": {
              "error": "Error",
              "error-description": "An error occurred while processing your donation."
            },
            "footer": {
              "confirmation-button": "Confirm my donation",
              "total": {
                "label": "Total : {amount}",
                "subtext": "An official donation receipt will be issued to you."
              }
            },
            "personal-donation-button": "Make a personal donation",
            "title": "Allocate your share of {teamName}'s donation"
          }
        },
        "error": {
          "internal": {
            "back-home-text": "Back to the homepage",
            "text": "Sorry, an error has occurred. Please try again later.",
            "title": "Internal error"
          },
          "not-found": {
            "back-home-text": "Back to the homepage",
            "text": "Sorry, we couldn’t find this page.",
            "title": "Error 404 – Page not found"
          }
        },
        "home": {
          "hero": {
            "dashboard-button": "View my dashboard",
            "description": "Nooé builds technologies that simplify corporate and personal giving. Our platform offers more than 3 000 Canadian registered charities ready to receive a donation. Each user is mobilized by giving back to the charities they are attached to.",
            "login-button": "Log in",
            "search-button": "Search for a registered charity",
            "text-circle": "Stay in the loop",
            "title": "A new way of giving"
          }
        },
        "join": {
          "code-label": "Your campaign code:",
          "code-placeholder": "Code",
          "consent-checkbox-label": "I accept the <a target=\"_blank\" href=\"https://www.nooe.org/en/termes-et-conditions\">terms and conditions</a> regarding the use of my personal data.",
          "email-error-message": "Please enter a valid email address.",
          "email-label": "Enter your email address to join the campaign:",
          "email-placeholder": "Email",
          "error-message": "An error occured while joining the campaign. Please try again or try to <a href=\"/en/login\">login</a> using your email address.",
          "join-button": "Join campaign",
          "joining-campaign": "Please wait while your account is being created…",
          "title": "Join a campaign",
          "view-email": "We've sent an email to <strong>{email}</strong> with a login link."
        },
        "join-campaign": {
          "code-placeholder": "Campaign code",
          "errors": {
            "no_active_campaign_for_code": "No active campaign found for this code.",
            "user_already_exists": "It looks like you've already joined this campaign. Please check if you are still logged in or <a href=\"/en/login\">log in</a> again with your email address."
          },
          "join-button": "Join campaign",
          "joining-campaign": "Please wait while the campaign is being joined…",
          "title": "Join a campaign"
        },
        "login": {
          "email-error-message": "Please enter a valid email address.",
          "email-placeholder": "Email",
          "error-message": {
            "email-not-authorized": "This email is not linked to a Nooé account.",
            "email-not-valid": "This email is not valid.",
            "generic": "An error occurred while signing in, please try again."
          },
          "no-team-for-user": "We cannot find a team associated with this email address.",
          "or": "or",
          "register-team": "Register your team",
          "sign-in-with-email": "Sign in with email",
          "sign-in-with-google": "Sign in with Google",
          "view-email": "We've sent an email to <strong>{email}</strong> with a login link."
        },
        "organization": {
          "general-informations": {
            "form": {
              "address": "Address",
              "cancel": "Cancel",
              "categories": "Categories",
              "city": "City",
              "description": "Description",
              "donation-impact": "Donation Impact",
              "errors": {
                "at-least-one-locale": "At least one value between French or English must be entered.",
                "field-is-empty": "This field can't be empty."
              },
              "facebook-url": "Facebook URL",
              "instagram-url": " Instagram URL",
              "linkedin-url": " LinkedIn URL",
              "logo-image-label": "Logo",
              "name": "Name",
              "postal-code": "Postal Code",
              "province": "Province",
              "region": "Region",
              "registration-number": "Registration number",
              "submit": "Save",
              "tiktok-url": " TikTok URL",
              "update-error": "⚠️ An error occurred while saving this charity's information.",
              "update-success": "The update of the charity information was completed successfully.",
              "website": "Website",
              "x-url": " X URL",
              "youtube-url": " YouTube URL"
            },
            "title": "General Informations"
          },
          "sidebar": {
            "links": {
              "general-informations": "General Informations"
            }
          },
          "title": "Charity"
        },
        "profile": {
          "allocations-history": {
            "campaign": {
              "active": "active",
              "ended": "ended on {date}"
            },
            "campaign-title": "Donations",
            "go-to-donate": "Donate",
            "no-allocations": "You have not made any donations yet.",
            "organization-title": "Charities",
            "tab-list": {
              "campaign": "Display by campaign",
              "organization": "Display by charity",
              "total-allocations": "Total donations"
            },
            "title": "Donation history",
            "total-allocations-text": "Across all your campaigns, you have donated a total of {amount}.",
            "total-allocations-title": "Total donations"
          },
          "donations-history": {
            "header": {
              "amount": "Amount",
              "date": "Date",
              "download": "Download receipt",
              "organization": "Registered charities"
            },
            "no-result": "You have not made any personal donations yet.",
            "title": "Donations receipts"
          },
          "personal-information": {
            "form": {
              "communication-locale-label": "Language used in communications",
              "communication-locales": {
                "english": "English",
                "french": "French",
                "no-selected-value": "Default language"
              },
              "email-label": "Email",
              "errors": {
                "field-is-empty": "This field cannot be empty."
              },
              "first-name-label": "First name",
              "last-name-label": "Last name",
              "profile-image-label": "Profile picture",
              "submit": "Save",
              "update-error": "⚠️ An error occurred while saving your information, please try again later",
              "update-success": "✅ Your information has been successfully updated"
            },
            "title": "Personal information"
          },
          "sidebar": {
            "links": {
              "allocations-history": "Campaign history",
              "donations-receipts": "Donations receipts",
              "personal-information": "Personal information"
            }
          },
          "title": "My profile"
        },
        "receipt": {
          "address": "Address",
          "address-placeholder": "Example: 123, Main Street",
          "city": "City",
          "city-placeholder": "Example: Quebec City",
          "download": "Download receipt",
          "download-prompt": "This donation’s receipt was generated on {date}.",
          "first-name": "First name",
          "generate": "Submit",
          "generating-receipt": "Please wait while your receipt is being generated…",
          "generation-error": "An error occured while creating the receipt, please try again.",
          "last-name": "Last name",
          "locale": {
            "english": "English",
            "french": "French"
          },
          "personal-informations": {
            "description": "The personal information collected in this form is used only for the creation of the donation receipt. It will not be used for solicitation purposes.",
            "title": "Use of personal data"
          },
          "postal-code": "Postal Code",
          "postal-code-placeholder": "Example: X1X 1X1",
          "prompt": "Enter your information to generate your receipt.",
          "province": {
            "alberta": "Alberta",
            "british-columbia": "British Columbia",
            "label": "Province or Territory",
            "manitoba": "Manitoba",
            "new-brunswick": "New Brunswick",
            "newfoundland-and-labrador": "Newfoundland and Labrador",
            "northwest-territories": "Northwest Territories",
            "nova-scotia": "Nova Scotia",
            "nunavut": "Nunavut",
            "ontario": "Ontario",
            "prince-edward-island": "Prince Edward Island",
            "quebec": "Quebec",
            "saskatchewan": "Saskatchewan",
            "yukon": "Yukon"
          },
          "title": "Donation receipt",
          "validation-error": "An error occurred, please complete all fields again."
        }
      }
    },
    "external-links": {
      "contact-us": "https://www.nooe.org/en/contactez-nous",
      "register-organization": "https://www.nooe.org/en/contactez-nous",
      "register-team": "https://www.nooe.org/en/contactez-nous",
      "showcase-site": "https://www.nooe.org/en",
      "terms-and-conditions": "https://www.nooe.org/en/termes-et-conditions"
    },
    "general": {
      "alternate-locale": "Français",
      "application": {
        "name": "Nooé"
      }
    },
    "images-alt": {
      "campaign-message": "campaign message icon",
      "category": "Icon of category {name}",
      "external-link": "External link icon",
      "flower": "Flower icon",
      "no-result": "No result image",
      "organization": "Logo of charity {name}",
      "peace-sign": "Finger peace sign image",
      "profile": "Profile image",
      "restart": "Restart icon",
      "search": "search icon",
      "social-media": "Logo of {platform}",
      "success": "Success icon",
      "team": "Logo of team {teamName}"
    },
    "page-titles": {
      "allocations-history": "Campaign history",
      "application": "Nooé ~ Stay in the loop",
      "consent": "Consent request",
      "dashboard": "Dashboard",
      "discover": "Discover",
      "donate": "Charities",
      "donations-receipts": "Donations receipts",
      "internal-error": "Internal error",
      "join-campaign": "Join a campaign",
      "login": "Login",
      "not-found": "Page not found",
      "organization": {
        "general-informations": "General Informations",
        "index": "Charity"
      },
      "personal-information": "Personal Information",
      "profile": "My profile",
      "receipt": "Donation receipt"
    },
    "routes": {
      "campaign": {
        "donate": "donate",
        "index": "en/campaign/:campaignId"
      },
      "consent": "en/consent",
      "dashboard": "en/dashboard",
      "discover": "en/discover",
      "donate": {
        "index": "en/donate",
        "show": ":organizationId"
      },
      "index": {
        "index": "en"
      },
      "join": "en/join",
      "join-campaign": "en/join-campaign",
      "login": "en/login",
      "not-found": {
        "index": "en/not-found"
      },
      "organization": {
        "general-informations": "en/general-informations",
        "index": "en/charity"
      },
      "profile": {
        "allocations-history": "allocations-history",
        "donations-history": "personal-donations",
        "index": "en/my-profile",
        "personal-information": "personal-information"
      },
      "receipt": "en/receipt"
    }
  }], ["fr-ca", {
    "components": {
      "application-page-header": {
        "menu": {
          "content": {
            "contact-us": "Nous joindre",
            "home": "Tableau de bord",
            "how-it-works": "Comment ça fonctionne?",
            "join-campaign": "Rejoindre une campagne",
            "login": "Se connecter",
            "logout": "Déconnexion",
            "organization": "Mon organisme",
            "profile": "Mon profil",
            "search-organization": "Rechercher un organisme",
            "suggest-an-organization": "Proposer un organisme",
            "team-switcher": {
              "title": "Sélectionner une équipe"
            }
          },
          "cookies": "Paramètres des témoins",
          "terms-and-conditions": "Termes et conditions"
        }
      },
      "campaign-status-banner": {
        "allocations-left": "Il vous reste {amountLeft} $ en dons à attribuer, faites-le dès maintenant!",
        "already-donated": "Un grand merci pour votre générosité ! Si vous le souhaitez, vous pouvez encore faire un don personnel.",
        "ended": "La campagne est terminée. Merci pour vos dons!",
        "ends-soon": "La campagne prend fin dans {daysLeft, plural, =1 {1 jour} other {# jours}}.",
        "ends-today": "La campagne se termine aujourd’hui.",
        "never-donated": "C'est le moment idéal pour faire un don personnel!"
      },
      "dashboard": {
        "all-donations": {
          "completed": "Complété",
          "donations-sum": "Somme des dons",
          "goal": "de {goal}",
          "hide-details": "Cacher détails",
          "my-allocations": "Ma part du don de {teamName}",
          "my-personal-donations": "Mes dons personnels",
          "show-details": "Voir détails",
          "title": "Tous les dons"
        },
        "donate": "Faire un don",
        "last-activities": {
          "earlier": "Plus tôt",
          "empty-message-1": "Soyez le <em>premier</em> à donner.",
          "empty-message-2": "Nous vous invitons à le faire dès maintenant et contribuer aux causes qui vous tiennent à cœur. Chaque don compte!",
          "hours-ago": "Il y a {hours, plural, =1 {une heure} other {# heures}}",
          "less-than-a-minute-ago": "Il y a moins d'une minute",
          "minutes-ago": "Il y a {minutes, plural, =1 {1 minute} other {# minutes}}",
          "this-week": "Cette semaine",
          "title": "Les dernières activités",
          "today": "Aujourd’hui",
          "yesterday": "Hier"
        },
        "popular-categories": {
          "header-category": "Catégorie",
          "header-organism-count": "Nombre d'organismes",
          "header-total": "Total",
          "organizations": "{count, plural, =1 {1 Organisme} other {# Organismes}}",
          "title": "Catégories populaires"
        },
        "see-all-organizations": "Voir les organismes",
        "title": "Tableau de bord"
      },
      "form": {
        "translated-input": {
          "label": {
            "en": "EN",
            "fr": "FR"
          }
        }
      },
      "pages": {
        "consent": {
          "button-text": "Accepter les conditions",
          "checkbox-label": "J’accepte les conditions d’utilisation",
          "policy-description": "En cliquant sur « Accepter les conditions », vous acceptez les conditions d’utilisation de la plateforme Nooé. Vous pouvez les consulter en cliquant sur <a target=\"_blank\" href=\"https://www.nooe.org/termes-et-conditions\">ce lien</a>.",
          "title": "Demande de consentement"
        },
        "discover": {
          "back-button": "Retour",
          "loading": "Chargement…",
          "loading-error": "Oups! Nous n’avons pas obtenu le résultat escompté… Veuillez réessayer.",
          "organization-results": {
            "no-result-text": "Aucun organisme ne correspond à votre recherche.",
            "register-organization": "Proposer un organisme"
          },
          "retry": "Réessayer",
          "search": "Rechercher",
          "search-form": {
            "disclaimer": "Ces résultats sont générés grâce à l’intelligence artificielle. Par conséquent, ils peuvent varier et/ou ne pas correspondre à vos attentes. Une reformulation de votre requête peut être nécessaire pour obtenir de meilleurs résultats.",
            "placeholder": "Une cause à laquelle je m’attache est:",
            "region": "Région",
            "title": "Dites-nous ce qui vous tient à cœur"
          }
        },
        "donate": {
          "categories-list": {
            "title": "<em>Découvrez</em> les organismes"
          },
          "discover-with-ai": "Découvrir avec l’IA",
          "organization-list": {
            "count": "{count} résultats pour votre recherche",
            "footer": {
              "load-more-button": "Charger plus d’organismes"
            },
            "limit-exceeded": {
              "text": "La limite de résultats a été atteinte. Veuillez affiner votre recherche."
            },
            "no-params": "Commencez votre recherche pour un organisme.",
            "no-result": {
              "clear-filters": "Effacer les filtres",
              "register-organization": "Proposer un organisme",
              "text": "Aucun organisme ne correspond à votre recherche."
            },
            "quotes": {
              "quote1": "Notre mission: Augmenter le don annuel moyen au Québec.",
              "quote2": "Notre plateforme présente plus de 3000 organismes québécois enregistrés et prêts à recevoir un don.",
              "quote3": "En 2023, le don moyen au Québec était de 249 $, un montant bien en deçà de la moyenne canadienne.",
              "quote4": "Nooé permet de découvrir et de soutenir des organismes enregistrés aux quatre coins du Québec.",
              "quote5": "Le saviez-vous? <p>Nooé remet 100% des montants amassés aux organismes supportés. Aucuns frais ne sont déduits de votre don.</p>",
              "quote6": "La plateforme Nooé peut être utilisée lors d’événements. Mobilisez vos participants en les laissant choisir les causes à soutenir.",
              "quote7": "Saviez-vous que vous pouvez choisir de faire votre don à la Fondation Nooé? <p>Ces dons accélèrent notre développement et nous aident à atteindre notre mission.</p>",
              "quote8": "Le saviez-vous? <p>La Fondation Nooé émet des reçus pour les dons effectués, vous permettant de bénéficier des déductions fiscales appropriées.</p>"
            },
            "title": {
              "category": "{count, plural, =1 {Voici le résultat} other {Voici les # résultats}} de la catégorie {category}",
              "default": "{count, plural, =1 {Voici votre résultat} other {Voici vos # résultats}}"
            }
          },
          "preselected-organization-list": {
            "title": "{organizationCount, plural, =1 {L’organisme disponible} other {Les # organismes disponibles}} dans votre campagne"
          },
          "search-form": {
            "clear-all-button": "Tout effacer",
            "placeholder": {
              "category": "Catégories",
              "keywords": "Entrez un mot-clé",
              "region": "Régions"
            },
            "search-button": "Rechercher",
            "title": "Rechercher un organisme",
            "update-filter-button": "Actualiser"
          }
        },
        "donate-organization": {
          "back-button": "Retour",
          "claim-modal": {
            "description": "Complétez le formulaire ci-dessous pour devenir le représentant de cet organisme.",
            "on-error": "Une erreur est survenue lors de l’envoi de votre demande. Veuillez réessayer plus tard.",
            "on-success": "Votre demande a été envoyée avec succès. Nous vous contacterons sous peu.",
            "representative-email-error-message": "Veuillez entrer une adresse courriel valide.",
            "representative-email-placeholder": "Votre adresse courriel",
            "representative-last-name-placeholder": "Votre nom de famille",
            "representative-name-placeholder": "Votre prénom",
            "representative-phone-number-placeholder": "Votre numéro de téléphone",
            "representative-position-placeholder": "Votre poste au sein de l’organisme",
            "submit": "Envoyer la demande",
            "title": "Je représente cet organisme"
          },
          "donations": {
            "personal-donation-button": "Faire un don personnel",
            "personal-donation-button-repeat": "Faire un autre don",
            "team-donation-button": "Donnez votre part du don de {teamName}"
          },
          "edit-organization": "Modifier mon organisme",
          "impact": {
            "text-chunks": {
              "action-label": "ici",
              "end": "pour partager avec les donateurs de Nooé l’impact de leurs dons pour votre organisme.",
              "start": "Vous représentez cet organisme? Signifiez-nous le"
            },
            "title": "L’impact de votre don"
          },
          "main-content": {
            "claim-organization-notice": "Je représente cet organisme",
            "name": "Nom",
            "region": "Région"
          },
          "meta": {
            "address": "Adresse",
            "email-body": "Bonjour,\n\nJe souhaite apporter les corrections suivantes pour l’organisme: {organizationName}\n\n<< Veuillez nous fournir les informations à corriger >>",
            "email-subject": "Corrections à apporter à un organisme",
            "generated-content-notice-1": "Ce contenu généré automatiquement contient des erreurs?",
            "generated-content-notice-2": "Faites-nous le savoir!",
            "registration-number": "Numéro d’enregistrement",
            "website": "Site Web"
          },
          "personal-donation-modal": {
            "donation-already-made": "Vous avez déjà effectué un don de {amount} à cet organisme.",
            "donation-form": {
              "amount-placeholder": "Montant du don",
              "consent-section": {
                "checkbox-label": "J’accepte les conditions d’utilisation",
                "policy-description": "En cliquant sur « Accepter les conditions », vous acceptez les conditions d’utilisation de la plateforme Nooé. Vous pouvez les consulter en cliquant sur <a target=\"_blank\" href=\"https://www.nooe.org/termes-et-conditions\">ce lien</a>."
              },
              "email": {
                "confirmation-placeholder": "Confirmez votre adresse courriel",
                "error-message": "Veuillez entrer une adresse courriel valide.",
                "placeholder": "Adresse courriel",
                "unmatched-error-message": "Les adresses courriel ne sont pas identiques."
              },
              "error-messages": {
                "donation-amount-error-message": "Veuillez entrer un montant plus grand que zéro.",
                "minimum-donation-amount-error-message": "Pour nous aider à avoir un impact significatif, nous vous proposons d’effectuer un don minimum de {minimumDonation} dollars. Nous vous remercions de faire la différence!"
              },
              "footer": {
                "message": "Un reçu officiel de don vous sera émis.",
                "submit": "Confirmer mon don",
                "total": "Total : {amount}"
              },
              "platform-amount": {
                "custom-description": "À titre d’exemple, le frais de carte de crédit est de {amount} par transaction.",
                "custom-title": "Autre montant",
                "description": "Une plateforme québecoise qui redonne 100% des dons aux organismes!",
                "options-fees": "Frais de carte de crédit ({amount})",
                "options-other": "Autre",
                "options-percentage": "{percentage} ({amount})",
                "placeholder": "Inscrire le montant ici",
                "title": "Soutenez Nooé!"
              }
            },
            "error-message": "Une erreur est survenue lors du traitement de votre don. Veuillez réessayer plus tard.",
            "payment-form": {
              "submit": "Soumettre"
            },
            "payment-success": {
              "close": "Fermer",
              "subtext": "Un reçu officiel de don vous sera transmis par courriel d’ici la fin de l’année.",
              "text": "Votre don a été effectué avec succès!"
            },
            "title": "Finaliser votre don personnel"
          },
          "suggested-organizations-modal": {
            "content": {
              "current-allocation": "Organisme: {organizationName}",
              "current-amount-left": "Votre solde pour la campagne <b>{campaignName}</b> est maintenant de <b>{amount}</b>",
              "deleted-text": "Votre don a été supprimé",
              "deleted-title": "Confirmation",
              "success-title": "Don de {amount} complété"
            },
            "deleted-title": "Don supprimé",
            "organization-list": {
              "title": "Voici quelques organismes qui pourraient vous intéresser."
            },
            "success-title": "Merci d’être généreux"
          },
          "team-donation-modal": {
            "amount": {
              "current-allocation": "Vous avez déjà fait un don de <b>{amount}</b> à cet organisme durant la campagne <b>{campaignName}</b>",
              "current-amount-left": "Votre solde de la campagne <b>{campaignName}</b> est de <b>{amount}</b>",
              "no-amount-left": "Le montant alloué pour la campagne <b>{campaignName}</b> a été entièrement utilisé.",
              "title": "Merci pour votre don."
            },
            "donation": {
              "amount-list": {
                "amount-left": "Tout donner",
                "other-amount": "Autre"
              },
              "custom-amount": {
                "cancel-button": "Annuler",
                "error-messages": {
                  "maximum-allocation": "Le montant du don dépasse votre solde disponible",
                  "minimum-value": "Pour nous aider à avoir un impact significatif, nous vous proposons d’effectuer un don minimum de {minimumDonation} dollars. Nous vous remercions de faire la différence!",
                  "no-value": "Veuillez entrer un montant plus grand que zéro."
                },
                "placeholder": "Entrer un montant"
              },
              "delete-button": "Supprimer",
              "edit-button": "Modifier",
              "mention-text": "Vous pouvez modifier ou supprimer un don déjà fait étant donné que la campagne est encore en cours."
            },
            "error-box": {
              "error": "Erreur",
              "error-description": "Une erreur est survenue lors du traitement de votre don."
            },
            "footer": {
              "confirmation-button": "Confirmer mon don",
              "total": {
                "label": "Total : {amount}",
                "subtext": "Un reçu officiel de don vous sera émis."
              }
            },
            "personal-donation-button": "Faire un don personnel",
            "title": "Donner votre part du don de {teamName}"
          }
        },
        "error": {
          "internal": {
            "back-home-text": "Retour à l’accueil",
            "text": "Désolé, une erreure est survenue. Veuillez réessayer plus tard.",
            "title": "Erreur interne"
          },
          "not-found": {
            "back-home-text": "Retour à l’accueil",
            "text": "Désolé, nous n’avons pas trouvé cette page.",
            "title": "Erreur 404 – Page introuvable"
          }
        },
        "home": {
          "hero": {
            "dashboard-button": "Voir mon tableau de bord",
            "description": "Nooé bâtit des technologies qui simplifient le don corporatif et personnel. Grâce à notre plateforme 100% gratuite qui propose la découverte de plus de 3 000 organismes à travers la province, chaque utilisateur se mobilise en redonnant aux organismes qui lui tiennent à cœur.",
            "login-button": "Se connecter",
            "search-button": "Rechercher un organisme",
            "text-circle": "On s’attache à la cause",
            "title": "Avec Nooé, on s’attache à la cause."
          }
        },
        "join": {
          "code-label": "Votre code de campagne :",
          "code-placeholder": "Code",
          "consent-checkbox-label": "J’accepte les <a target=\"_blank\" href=\"https://www.nooe.org/termes-et-conditions\">conditions d’utilisation</a> relatives à l’utilisation de mes données personnelles.",
          "email-error-message": "Veuillez entrer une adresse courriel valide.",
          "email-label": "Entrez votre adresse courriel pour rejoindre la campagne :",
          "email-placeholder": "Courriel",
          "error-message": "Il semble que vous ayez déjà rejoint cette campagne. Veuillez vérifier si vous êtes toujours connecté ou <a href=\"/connexion\">vous connecter</a> à nouveau avec votre adresse courriel.",
          "join-button": "Rejoindre la campagne",
          "joining-campaign": "Veuillez patienter pendant la création de votre compte…",
          "title": "Rejoindre une campagne",
          "view-email": "Nous avons envoyé un courriel à <strong>{email}</strong> avec un lien de connexion."
        },
        "join-campaign": {
          "code-placeholder": "Code de campagne",
          "errors": {
            "no_active_campaign_for_code": "Aucune campagne active associée à ce code.",
            "user_already_exists": "Il semble que vous ayez déjà rejoint cette campagne. Veuillez vérifier si vous êtes toujours connecté ou <a href=\"/connexion\">vous connecter</a> à nouveau avec votre adresse courriel."
          },
          "join-button": "Rejoindre la campagne",
          "joining-campaign": "Veuillez patienter…",
          "title": "Rejoindre une campagne"
        },
        "login": {
          "email-error-message": "Veuillez entrer une adresse courriel valide.",
          "email-placeholder": "Courriel",
          "error-message": {
            "email-not-authorized": "Ce courriel n’est pas lié à un compte Nooé.",
            "email-not-valid": "Ce courriel n’est pas valide.",
            "generic": "Une erreur est survenue lors de la connexion, veuillez réessayer."
          },
          "no-team-for-user": "Nous ne trouvons aucune entreprise associée à cette adresse e-mail.",
          "or": "ou",
          "register-team": "Inscrire mon entreprise",
          "sign-in-with-email": "Se connecter par courriel",
          "sign-in-with-google": "Se connecter avec Google",
          "view-email": "Nous avons envoyé un courriel à <strong>{email}</strong> avec un lien de connexion."
        },
        "organization": {
          "general-informations": {
            "form": {
              "address": "Adresse",
              "cancel": "Annuler",
              "categories": "Catégories",
              "city": "Ville",
              "description": "Description",
              "donation-impact": "Impact des dons",
              "errors": {
                "at-least-one-locale": "Au moins une valeur entre français ou anglais doit être saisie.",
                "field-is-empty": "Ce champ ne peut être vide."
              },
              "facebook-url": "URL Facebook",
              "instagram-url": "URL Instagram",
              "linkedin-url": "URL LinkedIn",
              "logo-image-label": "Logo",
              "name": "Nom",
              "postal-code": "Code postal",
              "province": "Province",
              "region": "Région",
              "registration-number": "Numéro d’enregistrement",
              "submit": "Sauvegarder",
              "tiktok-url": "URL TikTok",
              "update-error": "⚠️ Une erreur est survenue lors de la sauvegarde des informations de l’organisme.",
              "update-success": "La sauvegarde des informations de l’organisme s’est effectuée avec succès.",
              "website": "Site web",
              "x-url": "URL X",
              "youtube-url": "URL YouTube"
            },
            "title": "Informations générales"
          },
          "sidebar": {
            "links": {
              "general-informations": "Informations générales"
            }
          },
          "title": "Organisme"
        },
        "profile": {
          "allocations-history": {
            "campaign": {
              "active": "en cours",
              "ended": "terminée le {date}"
            },
            "campaign-title": "Dons",
            "go-to-donate": "Donner",
            "no-allocations": "Vous n’avez fait aucun don pour le moment.",
            "organization-title": "Organismes",
            "tab-list": {
              "campaign": "Afficher par campagne",
              "organization": "Afficher par organisme",
              "total-allocations": "Total des dons"
            },
            "title": "Historique des campagnes",
            "total-allocations-text": "Au cours des différentes campagnes, vous avez donné un total de {amount}.",
            "total-allocations-title": "Total des dons"
          },
          "donations-history": {
            "header": {
              "amount": "Montant",
              "date": "Date",
              "download": "Télécharger le reçu",
              "organization": "Organisme"
            },
            "no-result": "Vous n’avez fait aucune donation personnelle pour le moment.",
            "title": "Reçus de dons"
          },
          "personal-information": {
            "form": {
              "communication-locale-label": "Langue utilisée lors des communications",
              "communication-locales": {
                "english": "Anglais",
                "french": "Français",
                "no-selected-value": "Langue par défaut"
              },
              "email-label": "Courriel",
              "errors": {
                "field-is-empty": "Ce champ ne peut être vide."
              },
              "first-name-label": "Prénom",
              "last-name-label": "Nom",
              "profile-image-label": "Photo de profil",
              "submit": "Sauvegarder",
              "update-error": "⚠️ Une erreur est survenue lors de la sauvegarde de vos informations, veuillez réessayer plus tard",
              "update-success": "✅ Vos informations ont été modifiées avec succès"
            },
            "title": "Informations personnelles"
          },
          "sidebar": {
            "links": {
              "allocations-history": "Historique des campagnes",
              "donations-receipts": "Reçus de dons",
              "personal-information": "Informations personnelles"
            }
          },
          "title": "Mon profil"
        },
        "receipt": {
          "address": "Adresse",
          "address-placeholder": "Exemple : 123, Rue Principale",
          "city": "Ville",
          "city-placeholder": "Exemple: Québec",
          "download": "Télécharger le reçu",
          "download-prompt": "Le reçu de ce don a été créé le {date}.",
          "first-name": "Prénom",
          "generate": "Soumettre",
          "generating-receipt": "Veuillez patienter pendant la création du reçu…",
          "generation-error": "Une erreur est survenue lors de la création du reçu, veuillez ré-essayer.",
          "last-name": "Nom de famille",
          "locale": {
            "english": "Anglais",
            "french": "Français"
          },
          "personal-informations": {
            "description": "Les informations personnelles recueillies dans ce formulaire sont utilisées uniquement pour la création du reçu de don. Elles ne seront pas utilisées à des fins de sollicitation.",
            "title": "Utilisation des données personnelles"
          },
          "postal-code": "Code postal",
          "postal-code-placeholder": "Exemple: X1X 1X1",
          "prompt": "Entrez vos informations pour obtenir votre reçu de don.",
          "province": {
            "alberta": "Alberta",
            "british-columbia": "Columbie Britannique",
            "label": "Province ou Territoire",
            "manitoba": "Manitoba",
            "new-brunswick": "Nouveau-Brunswick",
            "newfoundland-and-labrador": "Terre-Neuve et Labrador",
            "northwest-territories": "Territoires du Nord-Ouest",
            "nova-scotia": "Nouvelle-Écosse",
            "nunavut": "Nunavut",
            "ontario": "Ontario",
            "prince-edward-island": "Île-du-Prince-Édouard",
            "quebec": "Québec",
            "saskatchewan": "Saskatchewan",
            "yukon": "Yukon"
          },
          "title": "Reçu de don",
          "validation-error": "Une erreur est survenue, veuillez remplir tous les champs à nouveau."
        }
      }
    },
    "external-links": {
      "contact-us": "https://www.nooe.org/contactez-nous",
      "register-organization": "https://www.nooe.org/contactez-nous",
      "register-team": "https://www.nooe.org/contactez-nous",
      "showcase-site": "https://www.nooe.org/fr",
      "terms-and-conditions": "https://www.nooe.org/termes-et-conditions"
    },
    "general": {
      "alternate-locale": "English",
      "application": {
        "name": "Nooé"
      }
    },
    "images-alt": {
      "campaign-message": "Icône de message de campagne",
      "category": "Icône de la catégorie {name}",
      "external-link": "Icône de lien externe",
      "flower": "Icône de fleur",
      "no-result": "Image d'aucun résultat",
      "organization": "Logo de l'organization {name}",
      "peace-sign": "Image d'un signe de paix fait avec les doigts",
      "profile": "Image du profile",
      "restart": "Icône de recommencer",
      "search": "Icône de recherche",
      "social-media": "Logo de {platform}",
      "success": "Icône de succès",
      "team": "Logo de l'équipe {teamName}"
    },
    "page-titles": {
      "allocations-history": "Historique des campagnes",
      "application": "Nooé ~ On s’attache à la cause",
      "consent": "Demande de consentement",
      "dashboard": "Tableau de bord",
      "discover": "Découvrir",
      "donate": "Organismes",
      "donations-receipts": "Reçus de dons",
      "internal-error": "Erreur interne",
      "join-campaign": "Rejoindre une campagne",
      "login": "Se connecter",
      "not-found": "Page introuvable",
      "organization": {
        "general-informations": "Informations générales",
        "index": "Organisme"
      },
      "personal-information": "Informations personnelles",
      "profile": "Mon profil",
      "receipt": "Reçu de don"
    },
    "routes": {
      "campaign": {
        "donate": "donner",
        "index": "campagne/:campaignId"
      },
      "consent": "consentement",
      "dashboard": "dashboard",
      "discover": "decouvrir",
      "donate": {
        "index": "donner",
        "show": ":organizationId"
      },
      "index": {
        "index": ""
      },
      "join": "rejoindre",
      "join-campaign": "rejoindre-une-campagne",
      "login": "connexion",
      "not-found": {
        "index": "page-introuvable"
      },
      "organization": {
        "general-informations": "informations-generales",
        "index": "organisme"
      },
      "profile": {
        "allocations-history": "historique-des-dons",
        "donations-history": "dons-personnels",
        "index": "mon-profil",
        "personal-information": "informations-personnelles"
      },
      "receipt": "reçu"
    },
    "services": {
      "community": {
        "organizations": {
          "errors": {
            "fetch-limit-exceeded": "La limite de résultats a été atteinte. Veuillez affiner votre recherche."
          }
        }
      }
    }
  }]];
  _exports.default = _default;
});