define("ember-truth-helpers/helpers/is-equal", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEqual = isEqual;
  function isEqual([a, b]) {
    return (0, _utils.isEqual)(a, b);
  }
  var _default = (0, _helper.helper)(isEqual);
  _exports.default = _default;
});